import React, { useEffect } from "react";
import { FORM_D001 } from "../config/form";
import {required} from "../util/validate";
import {call_api,updateValues,isOperater} from "../util/util";
import { errorMessage,displayName } from "../config/config";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function D001(props) {
  document.title = displayName.D001;
  const room_id = props.roomId
  const [values, setValues] = React.useState(FORM_D001);
  const [freeplaceholder, setFreeplaceholder] = React.useState(' ');
  const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(true);
  let errCount = 0
  useEffect(() => {
    fetchInitialData(props.roomId)
  },[]);

  //初期表示処理
  async function fetchInitialData(_room_id){
    let form = {
      "room_id":_room_id,
    }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
    if(result.status_code==="200"){
      await setFreeplaceholder(result.extended_room_tag_info.place_holder)
      await setIsSubmitDisabled(!isOperater(result.roomInfo))
    }
  }

  //送信処理
  async function submit(){
    //入力チェック
    errCount = 0
    handleError("freeNyuryoku","requiredError",required(values.freeNyuryoku.value))
    window.scrollTo({top: 0,behavior: 'smooth',})
    //API連携
    if(errCount === 0){
      let form = {
        "room_id":room_id,
        "display_id":"D001",
        "freeNyuryoku":values.freeNyuryoku.value,
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
      if(result.status_code === "200"){
        setValues({ ...values, "systemError": false })
        liff.closeWindow()
        window.close()
      }else{
        setValues({ ...values, "systemError": true });
      }
    }
  }

  //以下ハンドラー
  //type=text
  function handleInputChange(e) {
    handleValue(e.target.name,e.target.value)
  }
  function handleValue(name, value) {
      updateValues({ [name]: { value } },setValues);
  }
  function handleError(name, error, bool) {
      const updatedValues = { [name]: { [error]: bool } };
      if (bool) errCount += 1;
      updateValues(updatedValues,setValues);
  }

  return (
    <div>
      <Header label="エラー検証中ver4"/>
      <div id="form">
        <form action="">
          <section className="area">
            <section>
              <div className="formSet">
                <h3 className="required">情報を入力ください</h3>
                  <p><textarea className={values.freeNyuryoku.requiredError ? "is-invalid" :""} onChange={handleInputChange} value={values.freeNyuryoku.value} name="freeNyuryoku" rows="3" maxLength="500" placeholder={freeplaceholder}></textarea>
                  </p>
              </div>
              <div>
              {values.freeNyuryoku.requiredError && <p className="validation-error-txt">{errorMessage.required}</p>}
              </div>
            </section>            
          </section>
          <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
        </form>
      </div>
      <Footer/>
    </div>
  );
}